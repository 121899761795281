import { createApp } from 'vue'
import router from "./routes";
import App from './App.vue'
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import Paginator from 'primevue/paginator';
import "/node_modules/primeflex/primeflex.css"
import "primevue/resources/themes/lara-light-blue/theme.css"
import PrimeVue from 'primevue/config'
import './assets/ styles/global.scss'
import Sidebar from "./components/common/sidebar/Sidebar.vue";
import {createPinia} from "pinia";
import {i18n} from './lang'
import AppHeader from "./components/common/AppHeader.vue";
import ActionButtonGroup from "./components/common/ActionButtonGroup.vue";
import BaseButton from "./components/common/BaseButton.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import "vue-toastification/dist/index.css";
import Toast, {POSITION} from "vue-toastification";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const optionsForAlertDialog = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};


const pinia=createPinia();
const app=createApp(App);
app.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 4000,
});


app.use(router);
app.use(pinia);
app.use(PrimeVue,{ripple: true });
app.use(i18n);
app.use(VueSweetalert2, optionsForAlertDialog);
app.component("Sidebar", Sidebar);
app.component("AppHeader",AppHeader);
app.component("BaseButton", BaseButton)
app.component("ActionButtonGroup", ActionButtonGroup);
app.component("InputText", InputText);
app.component("Dropdown", Dropdown);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Paginator", Paginator);
app.component("Skeleton", Skeleton)
app.component("ProgressSpinner",ProgressSpinner);
app.mount('#app');
