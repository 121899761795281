<template>
  <div @click="logoutAction" class="logout_container">
    <div class="sidebar_menu_item">
      <div class="sidebar_menu_item_icon">
        <img :src="LogoutIcon" alt="icn" />
      </div>
      <p class="sidebar_menu_item_title">{{ t('logout') }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { LogoutIcon } from '../../../plugins/icons.ts';
import { clearLocalStorage } from '../../../utils/tokenHelper.ts';
const { t } = useI18n();
const logoutAction = () => {
  clearLocalStorage();
  window.location = import.meta.env.VITE_VUE_APP_MICROSOFT_LOGOUT_AUTH_LINK;
};
</script>
