import router from "../../../routes";
import { getRefreshToken, setAuthTokens } from "../../../utils/tokenHelper.ts";
import axios from '../../api';
import { GET_AUTH_TOKEN, GET_REFRESH_AUTH_TOKEN } from "./endpoints.ts";
import { AuthenticationResponse } from "./types.ts";
export const getAuthToken=async (code:string)=>{
    try {
        const tokens:AuthenticationResponse =await axios.post(GET_AUTH_TOKEN, {
            authorization_code: code,
            redirect_url:import.meta.env.VITE_VUE_AD_REDIRECT_URL
        })
        if(tokens.refresh_token && tokens.id_token){
            setAuthTokens(tokens);
            await router.replace({name:"devices"})
        }
    }catch (error){
        console.log(error)
    }
}
export const refreshToken = async ()=>{
    const refreshToken= getRefreshToken();
    try {
        const tokens:AuthenticationResponse =await axios.post(GET_REFRESH_AUTH_TOKEN, {
            refresh_token: refreshToken,
        })
        if(tokens.refresh_token){
            setAuthTokens(tokens);
            return tokens;
        }
        return null;
    }catch(e){
        console.log(e)
    }
}