import {AuthenticationResponse} from "../services/modules/auth/types.ts";

export const getRefreshToken = ():string| null =>{
    return localStorage.getItem("refresh_token");
}
export const getIdToken=():string|null =>{
    return localStorage.getItem("id_token");
}
export const getTokenType=():string|null =>{
    return localStorage.getItem("token_type");
}
export const setAuthTokens=(tokens:AuthenticationResponse)=>{
    localStorage.setItem("refresh_token", tokens.refresh_token);
    localStorage.setItem("id_token", tokens.id_token);
    localStorage.setItem("token_type", tokens.token_type);
}

export const clearLocalStorage = ()=>{
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("token_type");
}