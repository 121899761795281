import {checkIfAuthenticated} from "../guards/checkIfAuthenticated.ts";
import {RouteRecordRaw} from "vue-router";
import {checkIfManager} from "../guards/checkIfManager.ts";

export const dashboardRoute:RouteRecordRaw={
    path:"dashboard",
    component:()=>import("../../Layouts/DashboardLayout.vue"),
    beforeEnter:[checkIfAuthenticated],
    children:[
        {
            path:"devices",
            component:()=>import("../../pages/Devices.vue"),
            name:"devices",
            children:[
                {
                    path:"add-new",
                    component:()=>import("../../components/modules/device/AddDevice.vue"),
                    name:"new-device",
                    beforeEnter:[checkIfManager]
                },
                {
                    path:"add-bulk",
                    component:()=>import("../../components/modules/device/BulkUpload/AddDeviceBulk.vue"),
                    name:"new-device-bulk",
                    beforeEnter:[checkIfManager]
                },
                {
                    path:"details/:id",
                    component:()=>import("../../components/modules/device/details/DeviceDetails.vue"),
                    name:"device-details",
                },
                {
                    path:"edit/:id",
                    component:()=>import("../../components/modules/device/AddDevice.vue"),
                    name:"device-edit",
                    beforeEnter:[checkIfManager]
                },
                {
                    path:"bulk-edit",
                    component:()=>import("../../components/modules/device/BulkUpload/BulkEdit.vue"),
                    name:"device-bulk-edit",
                    beforeEnter:[checkIfManager]
                }
            ]
        },
        {
            path:"rights_management",
            component:()=>import("../../pages/RightsManagement.vue"),
            name:"rights-management"
        },
        {
            path:"settings",
            component:()=>import("../../pages/Settings.vue"),
            name:"settings"
        }
    ]
}