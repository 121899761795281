<template>
  <div :class="`sidebar_menu_item_container ${route.name===props.routeName?'active':''}`" @click="routeChangeHandler">
    <div class="sidebar_menu_item">
      <div class="sidebar_menu_item_icon">
        <img :src="props.icon" alt="icn">
      </div>
      <p class="sidebar_menu_item_title">{{props.title}}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useRoute, useRouter} from "vue-router";
const props= defineProps({
  routeName:{
    type:String,
    default:""
  },
  title:{
    type:String,
    default:""
  },
  icon:{
    type:String,
    default:""
  }
})
const router=useRouter();
const route=useRoute();
const routeChangeHandler= ()=>{
  router.push({name:props.routeName})
}
</script>