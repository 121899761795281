import {SuccessObjectResponse} from "../types.ts";
import {ProfileResponse} from "./types.ts";
import axios from "../../api";
import {GET_PERSONAL_PROFILE} from "./endpoints.ts";

export const fetchProfile=async ():Promise<SuccessObjectResponse<ProfileResponse> | null>  =>{
    try {
        const profile:SuccessObjectResponse<ProfileResponse> =await axios.get(GET_PERSONAL_PROFILE);
        if(profile.status.code===200){
            return profile
        }
        return null;
    }catch (e){
        console.log(e)
        return null;
    }
}