import {defineStore} from "pinia";
import {fetchProfile} from "../../../services/modules/profile";
import {checkIfSelectedOrgExistInNeOrList} from "../../../utils/orgUtils.ts";
import {ROLES} from "../../../services/modules/profile/types.ts";

interface ProfileState{
    isLoading:boolean;
    full_name:string;
    organizations:{
        id:number;
        name:string;
        logo:string
        role:ROLES;
    }[];
    designation:string;
    selectedOrganization:{
        id:number;
        name:string;
        logo:string;
        role:ROLES;
    } | null;

}
export const useProfileStore = defineStore("profile", {
    state:():ProfileState =>({
        isLoading:false,
        full_name:"",
        organizations:[],
        designation:"",
        selectedOrganization: null

    }),
    actions:{
       async getProfile(){
           this.isLoading=true;
           const profile = await fetchProfile();
           if(profile){
               const orgs=profile.data.profiles.map(profile=>{
                   return {
                       role:profile.role==="manager_org"?ROLES.MANAGER:ROLES.SUPPORT,
                       ...profile.organization
                   }
               });
               const setGetSelectedOrgFromLocalStorage = checkIfSelectedOrgExistInNeOrList(orgs);
               this.full_name=profile.data.full_name;
               this.organizations = orgs;
               this.selectedOrganization=setGetSelectedOrgFromLocalStorage;
               this.designation = profile.data.designation;
           }
           this.isLoading=false;
       }
    }
})