export type Organization={
    id:number;
    name:string;
    logo:string;
}
export type Profile={
    role:ROLES;
    organization:Organization;
}
export enum ROLES{
    MANAGER="manager_org",
    SUPPORT="support_org"
}
export type ProfileResponse={
    full_name: string;
    profiles: Profile[];
    designation: string;
}