import {createI18n} from "vue-i18n";

let locale = localStorage.getItem('userLang');
if (!locale) {
    localStorage.setItem('userLang', 'Norwegian');
    locale = 'Norwegian';
}

import English from './module/en-US.json';
import Norwegian from './module/no-NO.json';

const messages = {
    English,
    Norwegian
}


export const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale, // set locale
    fallbackLocale: 'Norwegian', // set fallback locale
    messages,
})

export const t = i18n.global.t