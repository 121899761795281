<template>
  <div class="sidebar_footer_item_container">
    <div class="sidebar_footer_item">
      <div class="sidebar_footer_item_icon">
        <p>{{ CharSplit(full_name) }}</p>
      </div>
      <div class="sidebar_footer_item_details">
        <p class="subtitle">Admin</p>
        <p class="title">{{textTruncate(full_name)}}</p>
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">
import {useProfileStore} from "../../../stores/modules/profile";
import {storeToRefs} from "pinia";
import {CharSplit, textTruncate} from "../../../utils/textHelpter.ts";
const profileStore = useProfileStore();
const {full_name}=storeToRefs( profileStore )

</script>