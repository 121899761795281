export const textTruncate = (line:string, length = 30, suffix = "...") => {
    if (line.length <= length) return line
    return line.substring(0, length - suffix.length) + suffix
}

export const CharSplit=(line:string)=>{
    let words = line.split(" ");
    if(words.length>=2){
        return words[0][0]+words[1][0];
    }
    return words[0][0] + words[0][1]
}