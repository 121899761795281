
export enum BaseButtonType{
    "FILL",
    "OUTLINE"
}
export enum IconButtonType{
    INFO,
    DELETE,
    EDIT

}
export interface BaseButtonProps{
    type:BaseButtonType;
    onClick:()=>void;
    title:string;
    disabled?:boolean;
}
export interface ButtonGroupPops{
    buttons:BaseButtonProps[]
}
export interface BaseModalProps{
    title:string;
}
export interface BaseInputTextProps{
    id:string;
    label?:string;
    placeholder?:string;
    error?:string;
    modelValue:any;
    readonly?:boolean
}
export interface BaseSelectInputProps{
    id:string;
    label?:string;
    placeholder?:string;
    error?:string;
    modelValue:any;
    colors:boolean;
    options:{
        label:string;
        value:string;
    }[];
}
export interface BaseColorInputProps{
    id:string;
    label:string;
    placeholder:string;
    error?:string;
    modelValue:any;
    colors:boolean;
    options:{
        label:string;
        value:string;
    }[];
}
export interface BaseOrganizationSelectInputProps{
    modelValue:any;
    options:{
        id:number;
        name:string;
        logo:string;
    }[];
}

export type OrganizationType={

    id:number;
    name:string;
    logo:string
} | null;

export type IconButtonProps={
    type:IconButtonType;
    onClick:()=>void;
    disabled?:boolean;
}
export type ErrorResponseType={
    response:{
        data:{
            status?:{
                code:number;
                message:string;
            };
            bad_data?:any;
            [key:string]:any;
        };
    };
}