import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {useProfileStore} from "../../stores/modules/profile";
import {ROLES} from "../../services/modules/profile/types.ts";

export const checkIfManager=( to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext)=>{
    console.log({to,from})
    const profileStore = useProfileStore()
    if(profileStore.selectedOrganization?.role===ROLES.MANAGER){
        next();
    }else {
        next({name:"devices"})
    }

}