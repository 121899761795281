<template>
  <aside class="sidebar_container">
      <div class="sidebar_brand_menu">
        <div class="sidebar_brand">
          <img src="/src/assets/logo/brand_logo_single.svg" alt="log"/>
          <img src="/src/assets/logo/brand-text-logo.svg" alt="log" class="logo_style"/>
        </div>
        <div class="sidebar_menu_container">
            <MenuItem
                :title="t('MobileDevices')"
                route-name="devices"
                :icon="MobileIcon"
            />
        </div>
      </div>

      <div class="sidebar_footer">
        <div>
          <LogoutButton/>
        </div>
        <SidebarFooterItem/>
      </div>
  </aside>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { MobileIcon } from "../../../plugins/icons";
import LogoutButton from "./LogoutButton.vue";
import MenuItem from "./MenuItem.vue";
import SidebarFooterItem from "./SidebarFooterItem.vue";
const {t} = useI18n()
</script>
<style scoped lang="scss">
.logo_style{
  height:2rem;
  width:100%;
  transform:scale(1);
}
</style>