import {createRouter, createWebHistory} from "vue-router";
import {dashboardRoute} from "./modules/dashboardRoute.ts";
import {authRoute} from "./modules/authRoute.ts";


const router=createRouter({
    history:createWebHistory(),
    routes:[
        {
            path:"/",
            redirect:{name:"devices"},
            children:[
                dashboardRoute,
                authRoute
            ]
        }
    ]
})
export default router