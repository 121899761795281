import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {getIdToken} from "../../utils/tokenHelper.ts";

export const checkIfAuthenticated=( to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext)=>{
    console.log({to,from})
    const accessToken = getIdToken();
    if(accessToken!==null){
        next();
    }else {
       window.location=import.meta.env.VITE_VUE_APP_MICROSOFT_AUTH_LINK
    }

}