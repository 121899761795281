import axios from "axios";
import {clearLocalStorage, getIdToken, getTokenType, setAuthTokens} from "../../utils/tokenHelper.ts";
import {refreshToken} from "../modules/auth";
import {GET_REFRESH_AUTH_TOKEN} from "../modules/auth/endpoints.ts";
import {useToast} from "vue-toastification";
import {ErrorResponseType} from "../../types/componentPropsType.ts";


const instance = axios.create({
    baseURL:"",
    headers: {
        Accept        : "application/json",
        "Content-Type": "application/json",
    },
    timeout: 30000,
});
instance.defaults.baseURL=import.meta.env.VITE_VUE_APP_BASE_URL;
instance.interceptors.request.use((config)=>{
    const idToken = getIdToken();
    const tokenType= getTokenType();
    config.headers.Authorization=idToken?`${tokenType} ${idToken}`:'';
    return config;
});
instance.interceptors.response.use(
     (response) => {
        // If the response status is 200, resolve with the data attribute
        if (response.status === 200 || response.status===201) {
            return response.data;
        }
        return response;
    },
    async (error) => {
         console.log(error)
        // If there's an error, reject with the error
        if (error.response.status === 401 && error.response.config.url!==GET_REFRESH_AUTH_TOKEN) {
            // Call an API to refresh the access token
            localStorage.removeItem('id_token')
            const tokens=await refreshToken();
            if(tokens) {
                // Retry the original request with the new access token
                setAuthTokens(tokens);
                const config = error.response.config;
                config.headers.Authorization = `Bearer ${tokens.access_token}`;
                return instance(config);
            }
        }else if(error.response.status === 401 ){
            clearLocalStorage();
            window.location=import.meta.env.VITE_VUE_APP_MICROSOFT_AUTH_LINK;
        }
        if((error as ErrorResponseType).response.data.status){
            useToast().error(error.response.data.status.message);
        }else {
            Object.keys((error as ErrorResponseType).response.data).filter(key=>{
                return key!=='status' && key!=='bad_data'
            }).forEach(key=>{
                useToast().error((error as ErrorResponseType).response.data[key][0]);
            })
        }

        return Promise.reject(error);
    }
)

export default instance;