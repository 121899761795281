import {ROLES} from "../services/modules/profile/types.ts";

export const setSelectedOrganizationTOLocalStorage=(org:{
    id:number;
    name:string;
    logo:string
}| null)=>{
    if(org)
    localStorage.setItem("selectedOrg",JSON.stringify(org));
}
export const getSelectedOrganizationFromLocalStorage=():{
    id:number;
    name:string;
    logo:string
}| null=>{
    const stringyfiedOrg = localStorage.getItem("selectedOrg");
    if(stringyfiedOrg){
        return JSON.parse(stringyfiedOrg) as {
            id:number;
            name:string;
            logo:string
        };
    }
    return null;
}
export const checkIfSelectedOrgExistInNeOrList =(list:{
    id:number;
    name:string;
    logo:string
    role:ROLES
}[])=>{
    const currentOrg=getSelectedOrganizationFromLocalStorage();
    if(currentOrg){
        const checkOrgFlag = list.find(org=>org.id===currentOrg.id);
        if(checkOrgFlag){
            return checkOrgFlag;
        }else{
            setSelectedOrganizationTOLocalStorage(list[0]);
            return list[0];
        }
    }else if(!currentOrg && list.length===0){
        return null;
    }else{
        setSelectedOrganizationTOLocalStorage(list[0]);
        return list[0];
    }
}