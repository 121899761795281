import lock_icon from "../assets/icons/lock_icon.svg";
import mobile_icon from "../assets/icons/mobile_iocon.svg";
import settings_icon from "../assets/icons/settings_icon.svg";
import close_icon from "../assets/icons/close-icon.svg"
import upload_icon from "../assets/icons/upload_icon.svg";
import file_icon from "../assets/icons/file-icon.svg";
import delete_icon from "../assets/icons/delete-icon.svg";
import org_logo from "../assets/logo/org-logo.svg";
import clear_icon from "../assets/icons/clear-icon.svg"
import edit_icon from "../assets/icons/edit-icon.svg"
import info_icon from "../assets/icons/info-icon.svg";
import empty_device_icon from "../assets/logo/empty-device.svg";
import logout_icon from "../assets/icons/log-out.svg";

export const LockIcon=lock_icon;
export const MobileIcon=mobile_icon;
export const SettingsIcon= settings_icon;
export const CloseIcon = close_icon;
export const UploadIcon=upload_icon;
export const FileIcon = file_icon;
export const DeleteIcon = delete_icon;
export const OrgLogo = org_logo;
export const ClearIcon = clear_icon;
export const EditIcon = edit_icon;
export const InfoIcon = info_icon;
export const EmptyDeviceIcon = empty_device_icon;
export const LogoutIcon = logout_icon;